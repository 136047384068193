

$secondaryHeadingColor: #ffffff;
$formLabelsColor: $secondaryHeadingColor;

$formPlaceHolderColor: #58595b;


@mixin bgImage($size: cover,$position: center top,$repeat: no-repeat, $color: transparent) {
  background-color: $color;
  background-position: $position;
  background-repeat: $repeat;
  background-size: $size;
}

@mixin propacMbSizeUp() {
  @media only screen and (min-width: 360px) {
    @content;
  }
}

@mixin fSize($size: 1rem) {
  font-size: $size;
  line-height: $size + 0.2;
}


//Big headings:
$family1: "Source Sans Pro";

//Buttons:
$family2: "Source Serif Pro", sans-serif;

//Labels, Placeholder inputs:
$family3: "Proxima Nova";
/*******************************************************/
/*******************************************************/
/*******************************************************/
/*******************************************************/
/******* Overwrite Bootstrap variables *****************/
$font-family-base: $family3;
$primary: #183665;
$secondary: #84c041;
$border-color: #a2a5a4;
$input-border-radius: 2px;
$btn-border-radius: 0;
$btn-padding-y: 0.2rem;
$btn-padding-x: 1.5rem;
$colors: (
  "gray-border": $border-color
);
$input-padding-y: 0.7rem;
$btn-font-family: $family2;



@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
