@import "../../../scss/variables.scss";

#success-modal {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1000;
	.modal-content {
		position: fixed;
		left: 50%;
		top: 50%;
		padding: 35px 40px;
		background-color: $primary;
		transform: translate(-50%, -50%);
		max-width: 500px;
		width: calc(100% - 20px);
		z-index: 2;
		@include media-breakpoint-up('md') {
			padding: 45px 80px;
			max-width: 700px;
		}
		
		& > div {
			& > div {
				max-height: calc(100vh - 70px);
				overflow: auto;
			}
			.close-corner-button {
				font-family: $family1;
				background-color: transparent;
				border: 0;
				color: $white;
				position: absolute;
				top: 20px;
				right: 30px;
				padding: 0px;
				cursor: pointer;
				display: block;
				@include fSize(1.5rem);
				@include media-breakpoint-up('md') {
					@include fSize(2rem);
				}
			}
		}
		h5 {
			font-family: $family1;
			font-weight: bold;
			@include fSize(2rem);
			margin: 35px 0;
			color: $white;
			@include media-breakpoint-up('md') {
				@include fSize(4rem);
				margin: 50px 0;
			}
		}
	}
	.bgmodal-dark {
		background-color: $secondary;
		opacity: 0.3;
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
}