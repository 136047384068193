@import "../../../scss/variables.scss";

header {
	
	padding: 60px 0 5px 0;

	.propac-logo {
		display: table;
		margin: 0 auto;
		max-width: 200px;
		width: 65%;
		@include media-breakpoint-up('sm') {
			display: none;
		}
		&.mobile{
			max-width: 100%;
			width: 85%;
		}
		&.desktop {
			display: none;
			max-width: 450px;
			@include media-breakpoint-up('sm') {
				display: table;
			}
			@include media-breakpoint-up('md') {
				max-width: 100%;
			}
		}
	}
	
	.drive-growth-logo {
		margin: 0 auto;
    display: table;
		max-width: 750px;
		width: 100%;
	}
}