@import "./variables.scss";

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


@font-face {
	font-family: 'Proxima Nova';
	src: url('../assets/fonts/ProximaNova/Regular.eot');
	src: url('../assets/fonts/ProximaNova/Regular.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/ProximaNova/Regular.woff2') format('woff2'),
			url('../assets/fonts/ProximaNova/Regular.woff') format('woff'),
			url('../assets/fonts/ProximaNova/Regular.ttf') format('truetype'),
			url('../assets/fonts/ProximaNova/Regular.svg#ProximaNova-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/SourceSansPro/Black.eot');
	src: url('../assets/fonts/SourceSansPro/Black.woff') format('woff'), url('../assets/fonts/SourceSansPro/Black.ttf') format('truetype'), url('../assets/fonts/SourceSansPro/Black.svg') format('svg');
	font-weight: bold;
	font-style: bold;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../assets/fonts/SourceSansPro/SemiBold.eot');
	src: url('../assets/fonts/SourceSansPro/SemiBold.woff') format('woff'), url('../assets/fonts/SourceSansPro/SemiBold.ttf') format('truetype'), url('../assets/fonts/SourceSansPro/SemiBold.svg') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Serif Pro';
	src: url('../assets/fonts/SourceSerifPro/SemiBold.eot');
	src: url('../assets/fonts/SourceSerifPro/SemiBold.woff') format('woff'), url('../assets/fonts/SourceSerifPro/SemiBold.ttf') format('truetype'), url('../assets/fonts/SourceSerifPro/SemiBold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Serif Pro';
	src: url('../assets/fonts/SourceSerifPro/SemiBoldItalic.eot');
	src: url('../assets/fonts/SourceSerifPro/SemiBoldItalic.woff') format('woff'), url('../assets/fonts/SourceSerifPro/SemiBoldItalic.ttf') format('truetype'), url('../assets/fonts/SourceSerifPro/SemiBoldItalic.svg') format('svg');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

html, body {
  font-size: 16px;
  line-height: 18px;
}

body {
  @include bgImage(cover, center, no-repeat, $secondary);
  background-image: url('../assets/bg.png');
}

.modal-opened {
  overflow: hidden !important;
  max-height: 100vh !important;
}

.blue-heading {
  font-family: $family1;
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 1.9rem;
  display: table;
  margin: 0 auto;
  color: $primary;
  text-align: center;
  max-width: 315px;
  white-space: normal;
  @include media-breakpoint-up('sm') {
    font-size: 2.5rem;
    line-height: 2.5rem;
    max-width: 383px;
  }
  @include media-breakpoint-up('md') {
    font-size: 3.89rem;
    line-height: 3.89rem;
    max-width: 640px;
  }
  @include media-breakpoint-up('lg') {
    font-size: 5.1rem;
    line-height: 5.1rem;
  }
  @include media-breakpoint-up('xl') {
    font-size: 6rem;
    line-height: 6rem;
    max-width: 1000px; 
  }
}
.white-heading {
  font-family: $family2;
  font-style: italic;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.4rem;
  display: table;
  margin: 0 auto;
  padding: 20px 0 30px 0;
  color: $white;
  background-color: $primary;
  position: relative;
  text-align: center;
  max-width: 300px;
  white-space: normal;
  @include media-breakpoint-up('sm') {
    font-size: 2.1rem;
    line-height: 2.1rem;
    max-width: 383px;
  }
  @include media-breakpoint-up('md') {
    font-size: 3.2rem;
    line-height: 3.2rem;
    max-width: 560px;
    
  }
  @include media-breakpoint-up('lg') {
    font-size: 4.1rem;
    line-height: 4.1rem;
    max-width: 710px;
    
  }
  @include media-breakpoint-up('xl') {
    font-size: 5rem;
    line-height: 5rem;
    max-width: 880px;
  }
  &::before {
    content: "";
    @include bgImage(100% 100%);
    background-image: url('../assets/left_side.png');
    position: absolute;
    left: -100px;
    top: 0;
    height: 100%;
    width: 100px;
  }
  &::after {
    content: "";
    @include bgImage(100% 100%);
    background-image: url('../assets/right_side.png');
    position: absolute;
    right: -100px;
    top: 0;
    height: 100%;
    width: 100px;
  }
}

.input-height {
  height: $input-height;
}

.full-width {
  width: 100%;
}

.table-center {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.main-content > div {
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;
  width: 100%;
  @include propacMbSizeUp() {
    width: 75%;
  }
  @include media-breakpoint-up('md') {
    width: 100%;
  }
}

/*******************************************************/
/******* Form ******************************************/
.drawer-form {
  margin-bottom: 30px;
  width: 100%;
  @include media-breakpoint-up('md') {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .wrap-fields {
    label {
      color: $white;
    }
  }
}

.subtitles {
  margin-bottom: 1rem;
  @include media-breakpoint-up('md') {
    margin-bottom: 50px;
  }
}

.label-required {
  color: $white;
}

.recaptcha {
  position: relative;
  margin-top: 15px;
  margin-bottom: 20px;
  display: block;
  height: 100px;
  @include media-breakpoint-up('md') {
    display: table;
    margin: 0 auto;
    height: auto;
  }
  & > div {
    display: table;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    @include media-breakpoint-up('md') {
      position: static;
      transform: none;
    }
  }
}

.general-error {
  font-weight: bold;
  border-radius: 5px;
  padding: 15px 10px;
  margin: 20px auto;
  display: table;
  color: $danger;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 400px;
  width: 100%;
}

/*******************************************************/
/*******************************************************/
/*******************************************************/
/*******************************************************/
/******* Overwrite Bootstrap ***************************/

.btn {
  font-style: italic;
  &.btn-primary {
    font-weight: bold;
    @include button-size($btn-padding-y, $btn-padding-x, 1.5rem, 1.6rem, $btn-border-radius);
    @include media-breakpoint-up('sm') {
      @include button-size($btn-padding-y + 0.5, $btn-padding-x, 1.8rem, 1.9rem, $btn-border-radius);
    }
    &[disabled] {
      cursor: not-allowed;
    }
  }
}